import { debounce } from "../helper/functions";
import { ON_CHANGE_DEBOUNCE_TIMER } from "../helper/constants";
import { fetchConfig } from "../helper/fetch-config";

class CartRemoveButton extends HTMLElement {
  constructor() {
    super();

    this.addEventListener('click', (event) => {
      event.preventDefault();
      const cartItems = this.closest('cart-items') || this.closest('cart-drawer-items');
      cartItems.updateQuantity(this.dataset.index, 0);
    });
  }
}

customElements.define('cart-remove-button', CartRemoveButton);

if (!customElements.get('cart-note')) {
  customElements.define(
    'cart-note',
    class CartNote extends HTMLElement {
      constructor() {
        super();

        this.addEventListener(
          'change',
          debounce((event) => {
            let body = ''
            if(event.target.getAttribute('name') == 'note'){
              body = JSON.stringify({ note: event.target.value });
            } else {
              body = JSON.stringify({ attributes: {
                isGift: event.target.checked
              } });
            }
            fetch(`${routes.cart_update_url}`, { ...fetchConfig(), ...{ body } });
          }, ON_CHANGE_DEBOUNCE_TIMER)
        );
      }
    }
  );
}
